import { __decorate } from "tslib";
import { AppTheme } from '@d24/modules/front';
import { Component, Prop, Ref, Vue } from 'vue-property-decorator';
import { EventbusType, ModalType } from '@movecloser/front-core';
import { EModal } from '@contract/modal';
import { Inject } from '@plugin/inversify';
import { SiteLogoVariant, SiteServiceType } from '@service/site';
/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 * @author Agnieszka Zawadzka <agnieszka.zawadzka@movecloser.pl>
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 * @author Michał Rossian <michal.rossian@movecloser.pl>
 */
let Navbar = class Navbar extends Vue {
    constructor() {
        super(...arguments);
        // Determine the limit where the appropriate navbar classes should be rendered
        this.minEls = 6;
        /**
         * Determines whether the `<Sidebar>` component is currently open.
         */
        this.isSidebarOpen = false;
        /**
         * `[style]` declaration for the "my account" button.
         */
        this.myAccountBtnStyle = null;
    }
    /**
     * Determines whether the current application theme is `AppTheme.PremiumZone`.
     */
    get isPremiumZone() {
        return this.theme === AppTheme.PremiumZone;
    }
    /**
     * Determines whether the current application theme is `AppTheme.PremiumZone`.
     */
    get isKadeciRP() {
        return this.siteService.getActiveSite().id === 11;
    }
    /**
     * Alternative text for the logotype image.
     */
    get logotypeAlt() {
        return `${this.$t('components.navbar.logotype-of-the-xyz-site')} ${this.site.name}`;
    }
    /**
     * Return logged user
     */
    get user() {
        return this.$store.getters['profile/me'];
    }
    /**
     * Return checkIsPremium
     */
    get isPremium() {
        if (!this.user) {
            return false;
        }
        return !!(this.user.subscription && this.user.subscriptionPeriod !== null) || !!this.user.prevSubscription;
    }
    /**
     * Return subscription button target
     * @private
     */
    get subBtnTarget() {
        return this.isPremium ? { target: { name: 'profile.subscription' } }
            : { target: { name: 'subscription.choose.product' } };
    }
    /**
     * Determines whether the User is currently logged-in.
     */
    get isUserLoggedIn() {
        return this.$store.getters['profile/isUserLoggedIn'];
    }
    /**
     * Determines whether the current page is homepage.
     */
    get isHomepage() {
        return this.$route.path === '/';
    }
    /**
     * Determines site meta title.
     */
    get siteMetaTitle() {
        return this.$store.getters.siteMetaTitle;
    }
    /**
     * Logotype SVG file to render.
     */
    get logotype() {
        const logoSize = this.site.properties.logoSize;
        return {
            src: this.site.logo[SiteLogoVariant.Dark] || '',
            width: logoSize === null || logoSize === void 0 ? void 0 : logoSize.width,
            height: logoSize === null || logoSize === void 0 ? void 0 : logoSize.height
        };
    }
    /**
     * Return current Site.
     */
    get site() {
        return this.siteService.getActiveSite();
    }
    /**
     * Current application theme.
     */
    get theme() {
        return this.siteService.getActiveSiteTheme();
    }
    /**
     * Close sidebar when click on link.
     */
    closeSidebar(event) {
        event.preventDefault();
        if (this.$route.path !== '/') {
            this.$router.push('/');
        }
        else {
            window.scrollTo(0, 0);
        }
        this.eventBus.emit('ui:sidebar.close');
    }
    /**
     * Handles the `@click` event on the "menu" (hamburger) button.
     */
    onMenuBtnClick() {
        this.eventBus.emit('ui:sidebar.toggle');
        if (this.isSearching) {
            this.$emit('toggleSearching');
        }
    }
    /**
     * Handles the `@click` event on the "my account" button.
     */
    onMyAccountBtnClick() {
        this.goToMyAccount();
    }
    /**
     * Handles the `@click` event on the "log-in" button.
     */
    onLogInBtnClick() {
        this.modalConnector.open(EModal.LogIn);
    }
    /**
     * Handles the `@click` event on the "search" button.
     * Toggle is searching value
     */
    onSearchClick() {
        this.$emit('toggleSearching');
        if (this.isSidebarOpen) {
            this.eventBus.emit('ui:sidebar.toggle');
        }
    }
    /**
     * Attaches all the needed event listeners.
     */
    attachListeners() {
        this.eventBus.handle('ui:sidebar.changed', this.onSidebarChanged);
    }
    /**
     * Redirects the User to the "My account" view.
     */
    goToMyAccount() {
        this.$router.push({ name: 'profile.my-data' });
    }
    /**
     * Handles the `ui:sidebar.changed` event emitted by the `EventBus`.
     */
    onSidebarChanged(event) {
        if (typeof event.payload === 'undefined') {
            return;
        }
        this.isSidebarOpen = event.payload.isOpen;
    }
    /**
     * Sets the absolute position of the "my account" button.
     */
    setMyAccountBtnPosition() {
        if (!this.isPremiumZone) {
            return;
        }
        if (!this.searchBtnRef) {
            throw new Error('<Navbar>.setMyAccountBtnPosition(): [this.searchBtnRef] is NOT available!');
        }
        const searchBtnRect = this.searchBtnRef.$el.getBoundingClientRect();
        this.myAccountBtnStyle = {
            top: searchBtnRect.top + searchBtnRect.height / 2 + 'px',
            left: searchBtnRect.left + 'px'
        };
    }
    /**
     * Sets the value for the `--navbar-height` CSS variable.
     */
    setNavbarHeight() {
        const navbarHeight = typeof this.navRef !== 'undefined'
            ? typeof this.navRef.clientHeight === 'number'
                ? this.navRef.clientHeight - 1 + 'px' : undefined
            : undefined;
        if (typeof navbarHeight !== 'undefined') {
            // :root css selector can be reached with documentElement selector
            document.documentElement.style.setProperty('--navbar-height', navbarHeight);
        }
    }
};
__decorate([
    Prop({
        type: Array,
        required: false
    })
], Navbar.prototype, "links", void 0);
__decorate([
    Prop({
        type: Boolean,
        required: true
    })
], Navbar.prototype, "isSearching", void 0);
__decorate([
    Prop({
        type: String,
        required: false,
        default: ''
    })
], Navbar.prototype, "premiumButtonLink", void 0);
__decorate([
    Prop({
        type: Boolean,
        required: false,
        default: true
    })
], Navbar.prototype, "showSubscriptionBtn", void 0);
__decorate([
    Ref('nav')
], Navbar.prototype, "navRef", void 0);
__decorate([
    Ref('searchBtn')
], Navbar.prototype, "searchBtnRef", void 0);
__decorate([
    Inject(EventbusType)
], Navbar.prototype, "eventBus", void 0);
__decorate([
    Inject(ModalType)
], Navbar.prototype, "modalConnector", void 0);
__decorate([
    Inject(SiteServiceType)
], Navbar.prototype, "siteService", void 0);
Navbar = __decorate([
    Component({
        name: 'Navbar',
        mounted() {
            this.attachListeners();
            this.setNavbarHeight();
            this.setMyAccountBtnPosition();
        },
        updated() {
            this.setNavbarHeight();
        }
    })
], Navbar);
export { Navbar };
export default Navbar;
